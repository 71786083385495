import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from './axios';
import Transaction from './Transaction';
import NAV_LOGGED from './Navigate_Logged';
import Tail from './Tail';
import Loaderpage from './Loaderpage';
import lao_address from './lao_address';
import Transactionredeem from './Transactionredeem';
import moment from 'moment';
class User extends Component {
  componentDidMount() {
    document.title = 'MK | User info';
    const { t } = this.props;
    axios
      .get(process.env.REACT_APP_API.concat('public/members'))
      .then((res) => {
        var header = '';
        var alterheader = '';
        if (res.data.phone.length === 8) {
          header = '020';
        } else if (res.data.phone.length === 7) {
          header = '030';
        }
        if (res.data.alternate_phone.length === 8) {
          alterheader = '020';
        } else if (res.data.alternate_phone.length === 7) {
          alterheader = '030';
        }
        var data = [];
        var address = [];
        if (res.data.address) {
          address = res.data.address.split(',');
          var district;
          for (district in lao_address[address[0]]) {
            data.push(
              <option key={district} value={lao_address[address[0]][district]}>
                {t(lao_address[address[0]][district])}
              </option>
            );
          }
        }
        var expired_on;
        expired_on = res.data.expired_on.substring(0, 10);
        expired_on = expired_on.split('-');

        this.setState(
          Object.assign(this.state, {
            ...this.state,
            data: res.data,
            updateinfo: {
              ...this.state.updateinfo,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              phone: res.data.phone,
              email: res.data.email,
              gender: res.data.gender,
              age: res.data.age,
              address: res.data.address,
              alternate_phone: res.data.alternate_phone,
            },
            alternate_phone: alterheader.concat(res.data.alternate_phone),
            district: address[1],
            province: address[0],
            district_list: data,
            expired_on: expired_on,
            phone: header.concat(res.data.phone),
            alterheader: alterheader,
            pheader: header,
            isLoading: 0,
          })
        );
      })
      .catch((err) => {
        console.log('error');
        console.log(err.response);
        localStorage.clear();
      });
  }

  constructor() {
    super();
    this.state = {
      updateinfo: {
        phone: '',
        email: '',
        alternate_phone: '',
        address: '',
        gender: '',
        age: '',
      },
      changepass: {
        old_password: '',
        password: '',
      },
      pass_warn: '',
      email_error: '',
      data: {
        card_number: '',
        email: '',
        created_at: '',
        expired_on: '',
        id: '',
        first_name: '',
        last_name: '',
        member_type: {
          id: '',
          name: '',
          percent: '',
        },
        member_type_id: '',
      },
      cfpassword: '',
      status: {
        update: '',
        color: 'text-success',
      },
      form_warn: {
        'Member.Email': '',
        'Member.Phone': '',
      },
      phone: '',
      isLoading: 1,
      pheader: '',
      district: '',
      province: '',
    };
    this.UpdateInfo = this.UpdateInfo.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validateNumber = this.validateNumber.bind(this);
    this.validatePass = this.validatePass.bind(this);
    this.UpdateInfo = this.UpdateInfo.bind(this);
    this.Changepassword = this.Changepassword.bind(this);
    this.onInputchange = this.onInputchange.bind(this);
    this.close_btn = this.close_btn.bind(this);
    this.validateNewPass = this.validateNewPass.bind(this);
    this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
    this.onInputaddress = this.onInputaddress.bind(this);
    this.listchoose = this.listchoose.bind(this);
    this.nextPath = this.nextPath.bind(this);
    this.formatMoney = this.formatMoney.bind(this);
  }
  formatMoney(amount) {
    return new Intl.NumberFormat('la-LA').format(amount);
  }
  validatePhoneNumber(event) {
    var number = event.target.value.replace(' ', '');
    const numregex = /020+[0-9]{8}|030+[0-9]{7}/;
    var res;
    if (numregex.test(number)) {
      res = number.slice(3);
      this.setState(
        Object.assign(this.state, {
          ...this.state,
          updateinfo: {
            ...this.state.updateinfo,
            [event.target.name]: res,
          },
        })
      );
    } else {
      this.setState(
        Object.assign(this.state, {
          ...this.state,
          updateinfo: {
            ...this.state.updateinfo,
            [event.target.name]: '',
          },
        })
      );
    }
    this.setState(
      Object.assign(this.state, {
        ...this.state,
        [event.target.name]: event.target.value.replace(' ', ''),
      })
    );
  }

  close_btn() {
    this.setState(
      Object.assign(this.state, {
        ...this.state,
        status: {
          ...this.state.status,
          update: '',
        },
        data: {
          ...this.state.data,
          first_name: this.state.data.first_name,
          last_name: this.state.data.last_name,
          email: this.state.data.email,
          phone: this.state.data.phone,
        },
      })
    );
  }
  UpdateInfo() {
    axios
      .put(
        process.env.REACT_APP_API.concat('public/members'),
        this.state.updateinfo
      )
      .then((res) => {
        var header = '';
        if (res.data.phone.length === 8) {
          header = '020';
        } else if (res.data.phone.length === 7) {
          header = '030';
        }
        this.setState(
          Object.assign(this.state, {
            ...this.state,
            data: {
              ...this.state.data,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              phone: res.data.phone,
              address: res.data.address,
              alternate_phone: res.data.alternate_phone,
            },
            status: {
              ...this.state.status,
              update: 'data_changed',
              color: 'text-success',
            },
            form_warn: {
              uniquePhone: '*',
              email: '*',
            },
            pheader: header,
          })
        );
      })
      .catch((err) => {
        if (err.response.data) {
          if (err.response.data instanceof Array) {
            err.response.data.forEach((message) => {
              var a = message.FailedField;
              this.setState(
                Object.assign(this.state, {
                  ...this.state,
                  form_warn: {
                    ...this.state.form_warn,
                    [a]: message.Tag.concat(a),
                  },
                })
              );
            });
          } else {
            if (err.response.data.FailedField) {
              this.setState(
                Object.assign(this.state, {
                  ...this.state,
                  form_warn: {
                    [err.response.data.FailedField]:
                      err.response.data.Tag.concat([
                        err.response.data.FailedField,
                      ]),
                  },
                })
              );
            } else {
              this.setState(
                Object.assign(this.state, {
                  ...this.state,
                  status: {
                    update: err.response.data.message,
                    color: 'text-danger',
                  },
                })
              );
            }
          }
          console.log(err.response);
        } else {
          console.log(err);
        }
      });
  }
  Changepassword() {
    if (
      this.state.new_pass_warn !== 'cfpasswrong' &&
      this.state.changepass.password !== '' &&
      this.state.changepass.password.length >= 8
    ) {
      axios
        .put(
          process.env.REACT_APP_API.concat('public/change-password'),
          this.state.changepass
        )
        .then((res) => {
          this.setState((prevState) => {
            return {
              ...this.state,
              cfpassword: '',
              changepass: {
                old_password: '',
                password: '',
              },
              status: {
                ...this.state.status,
                psschanged: '',
                update: 'data_changed',
                color: 'text-success',
              },
            };
          });
          localStorage.clear();
        })
        .catch((err) => {
          if (err.response.data) {
            if (err.response.data instanceof Array) {
              err.response.data.forEach((message) => {
                this.setState(
                  Object.assign(this.state, {
                    ...this.state,
                    status: {
                      ...this.state.status,
                      [message.FailedField]: message.Tag.concat([
                        message.FailedField,
                      ]),
                      color: 'text-danger',
                    },
                  })
                );
              });
            } else {
              this.setState(
                Object.assign(this.state, {
                  ...this.state,
                  status: {
                    ...this.state.status,
                    [err.response.data.FailedField]:
                      err.response.data.Tag.concat([
                        err.response.data.FailedField,
                      ]),
                    color: 'text-danger',
                  },
                })
              );
              console.log(err.response);
            }
          } else {
            console.log(err);
          }
        });
    } else {
      this.setState(
        Object.assign(this.state, {
          ...this.state,
          status: {
            update: 'checkplease',
            color: 'text-danger',
          },
        })
      );
    }
  }
  nextPath(path) {
    this.props.history.push(path);
  }
  validateNewPass(event) {
    if (this.state.changepass.password !== event.target.value) {
      this.setState(
        Object.assign(this.state, {
          ...this.state,
          new_pass_warn: 'cfpasswrong',
          cfpassword: event.target.value,
        })
      );
    } else {
      this.setState(
        Object.assign(this.state, {
          ...this.state,
          new_pass_warn: '',
          cfpassword: event.target.value,
        })
      );
    }
  }
  validatePass(event) {
    const passregex = /^.{8,}$/;
    var password = event.target.value;
    var pass_warn_text;
    if ('old_password' !== event.target.name) {
      if (passregex.test(password)) {
        pass_warn_text = '';
      } else {
        pass_warn_text = 'pass_warn';
      }
    }
    this.setState(
      Object.assign(this.state, {
        ...this.state,
        pass_warn: pass_warn_text,
        changepass: {
          ...this.state.changepass,
          [event.target.name]: event.target.value,
        },
      })
    );
  }

  onInputaddress(event) {
    const { t } = this.props;
    if (event.target.name === 'province' || 'district') {
      if (event.target.name === 'province') {
        var data = [];
        var district;
        for (district in lao_address[event.target.value]) {
          data.push(
            <option
              key={district}
              value={lao_address[event.target.value][district]}
            >
              {t(lao_address[event.target.value][district])}
            </option>
          );
        }
        this.setState(
          Object.assign(this.state, {
            ...this.state,
            [event.target.name]: event.target.value,
            district: '',
            district_list: data,
          })
        );
      } else {
        this.setState(
          Object.assign(this.state, {
            ...this.state,
            district: event.target.value,
            updateinfo: {
              ...this.state.updateinfo,
              address: this.state.province.concat(',' + event.target.value),
            },
          })
        );
      }
    }
  }

  validateNumber(event) {
    var number = event.target.value;
    const numregex = /[^0-9]/;
    if (!numregex.test(number)) {
      this.setState(
        Object.assign(this.state, {
          updateinfo: {
            ...this.state.updateinfo,
            phone: number,
          },
        })
      );
    }
  }
  listchoose(event) {
    this.setState(
      Object.assign(this.state, {
        ...this.state,
        updateinfo: {
          ...this.state.updateinfo,
          [event.target.name]: event.target.value,
        },
      })
    );
  }
  validateEmail(event) {
    var email = event.target.value;
    const emailRegex = /\S+@\S+\.\S+/;
    var message = 'email_err';
    if (emailRegex.test(email)) {
      message = '';
    } else {
      console.log('email format is invalid');
      message = 'email_err';
    }
    this.setState(
      Object.assign(this.state, {
        ...this.state,
        email_error: message,
        updateinfo: {
          ...this.state.updateinfo,
          [event.target.name]: event.target.value.replace(' ', ''),
        },
      })
    );
  }

  onInputchange(event) {
    this.setState(
      Object.assign(this.state, {
        updateinfo: {
          ...this.state.updateinfo,
          [event.target.name]: event.target.value.replace(' ', ''),
        },
      })
    );
  }

  render() {
    const { t } = this.props;

    if (localStorage.getItem('access_token') !== null) {
      if (this.state.isLoading === 0) {
        return (
          <>
            <NAV_LOGGED />
            <div className='container-fluid clearfix bg-white'>
              <div className='' style={{}}>
                <div className='row'>
                  <div className='col-sm-4 divmargin nobottommargin p-4'>
                    <div className='row mb-3'>
                      <div className='info'>
                        <h2 style={{ marginBottom: '8px' }}>{t('cardinf')}</h2>
                      </div>
                      <div className='link entry-image'>
                        <center>
                          <img
                            className='img card-img-top'
                            alt='Card'
                            src={this.state.data.member_type.image}
                          ></img>
                        </center>
                      </div>
                      <div className='info '>
                        <h4>
                          {t('cardnum')} : {this.state.data.card_number}
                        </h4>
                        <h4>
                          {t('memtype')} : {this.state.data.member_type.name}
                        </h4>
                        <h4 style={{ color: 'red' }}>
                          {t('expires')} :{' '}
                          {this.state.expired_on[2] +
                            '/' +
                            this.state.expired_on[1] +
                            '/' +
                            this.state.expired_on[0]}
                        </h4>
                        <h4>
                          {t('points')} :{' '}
                          {this.formatMoney(this.state.data.point)}{' '}
                          <button
                            type='button'
                            style={{ fontSize: '0.9rem' }}
                            className='btn btn-green btn-sm '
                            onClick={() => (window.location.href = '/redeem')}
                          >
                            {t('redeembtn')}
                          </button>
                        </h4>
                        <h4 style={{ color: 'red' }}>
                          {t('point_expired')} :{' '}
                          {moment(
                            this.state.expired_on[2] +
                              '/' +
                              this.state.expired_on[1] +
                              '/' +
                              this.state.expired_on[0],
                            'DD/MM/YYYY'
                          )
                            .add(2, 'M')
                            .format('DD/MM/YYYY')}
                        </h4>
                        {/* <div className="d-grid gap-2">
                                                <button type="button" style={{backgroundColor:"#6C757D"}} className="button button-3d  " data-bs-toggle="modal" data-bs-target="#redeem">{t('redeem')}</button>
                                            </div> */}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='info '>
                        <h2 style={{ marginBottom: '8px' }}>
                          {t('cardownerinfo')}
                        </h2>
                        <h4>
                          {t('fullname')} : {this.state.data.first_name}{' '}
                          {this.state.data.last_name}
                        </h4>
                        <h4>
                          {t('pnumber')} :{' '}
                          {this.state.pheader.concat(this.state.data.phone)}
                        </h4>
                        {this.state.data.alternate_phone && (
                          <h4>
                            {t('alternate_phone')} :{' '}
                            {this.state.pheader.concat(
                              this.state.data.alternate_phone
                            )}
                          </h4>
                        )}
                        <h4>
                          {t('mail')} : {this.state.data.email}
                        </h4>
                        <h4>
                          {t('address')} : {t(this.state.province)}{' '}
                          {t(this.state.district)}
                        </h4>
                        <div className='row'>
                          <div className='col'>
                            <div className='d-grid gap-2'>
                              <button
                                type='button'
                                style={{ backgroundColor: '#6C757D' }}
                                className='button button-3d  '
                                data-bs-toggle='modal'
                                data-bs-target='#editinfo'
                              >
                                {t('editinf')}
                              </button>
                            </div>
                          </div>
                          <div className='col'>
                            <div className='d-grid gap-2'>
                              <button
                                type='button'
                                style={{ backgroundColor: '#6C757D' }}
                                className='button button-3d '
                                data-bs-toggle='modal'
                                data-bs-target='#changepsswd'
                              >
                                {t('chgpsswd')}
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <button type="button" style={{backgroundColor:"#6C757D"}} className="button button-3d  " data-bs-toggle="modal" data-bs-target="#editinfo">{t('editinf')}</button>
                                                <button type="button" style={{backgroundColor:"#6C757D"}} className="button button-3d " data-bs-toggle="modal" data-bs-target="#changepsswd">{t('chgpsswd')}</button> */}
                        <div className='row '>
                          <div className='d-grid gap-2'>
                            <div
                              className='modal fade'
                              id='redeem'
                              data-bs-backdrop='static'
                              data-bs-keyboard='false'
                              tabIndex='-1'
                              aria-labelledby='staticBackdropLabel'
                              aria-hidden='true'
                            >
                              <div className='modal-dialog modal-xl'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h4 className='modal-title' id='redeem'>
                                      {t('redeem')}
                                    </h4>
                                    <button
                                      type='button'
                                      className='btn-close'
                                      data-bs-dismiss='modal'
                                      aria-label='Close'
                                      onClick={this.close_btn}
                                    ></button>
                                  </div>
                                  <div className='modal-body'></div>
                                  <div className='modal-footer'>
                                    <label className={this.state.status.color}>
                                      {t(this.state.status.update)}
                                    </label>
                                    <button
                                      type='button'
                                      className='btn btn-secondary'
                                      data-bs-dismiss='modal'
                                      onClick={this.close_btn}
                                    >
                                      {t('close')}
                                    </button>
                                    <button
                                      className='button button-3d button-primary nomargin'
                                      data-bs-target='#carouselExampleSlidesOnly'
                                      data-bs-slide='next'
                                    >
                                      {t('next')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-grid gap-2'>
                            <div
                              className='modal fade'
                              id='editinfo'
                              data-bs-backdrop='static'
                              data-bs-keyboard='false'
                              tabIndex='-1'
                              aria-labelledby='staticBackdropLabel'
                              aria-hidden='true'
                            >
                              <div className='modal-dialog'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h4 className='modal-title' id='editinfo'>
                                      {t('editinf')}
                                    </h4>
                                    <button
                                      type='button'
                                      className='btn-close'
                                      data-bs-dismiss='modal'
                                      aria-label='Close'
                                      onClick={this.close_btn}
                                    ></button>
                                  </div>
                                  <div className='modal-body'>
                                    <form
                                      className='nobottommargin'
                                      onSubmit={this.onSubmitForm}
                                    >
                                      {/* <div className="col_full">
                                                                            <div className="mb-3">
                                                                                <label htmlFor="register-form-cardno">
                                                                                    {t('personinf')}
                                                                                </label>
                                                                            </div>
                                                                        </div> */}
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <label htmlFor='register-form-cardno'>
                                            {t('pnumber')} :{' '}
                                            <label className='text-danger'>
                                              {' '}
                                              *
                                            </label>
                                            <label className='text-danger'>
                                              {t(
                                                this.state.form_warn[
                                                  'Member.Phone'
                                                ]
                                              )}
                                            </label>
                                          </label>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='fas fa-mobile-alt'></i>
                                            </span>
                                            <input
                                              type='text'
                                              name='phone'
                                              className='form-control'
                                              maxLength='11'
                                              placeholder='xxxxxxxx'
                                              value={this.state.phone}
                                              onChange={
                                                this.validatePhoneNumber
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <label htmlFor='register-form-cardno'>
                                            {t('pnumber-nd')} :{' '}
                                            <label className='text-danger'>
                                              {t(
                                                this.state.form_warn[
                                                  'Member.Phone'
                                                ]
                                              )}
                                            </label>
                                          </label>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='fas fa-mobile-alt'></i>
                                            </span>
                                            <input
                                              type='text'
                                              name='alternate_phone'
                                              className='form-control'
                                              maxLength='11'
                                              placeholder='xxxxxxxx'
                                              value={this.state.alternate_phone}
                                              onChange={
                                                this.validatePhoneNumber
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <div className='row'>
                                            <div className='col'>
                                              <label htmlFor='register-form-user'>
                                                {t('mail')}:
                                                <label className='text-danger'>
                                                  {t(
                                                    this.state.form_warn[
                                                      'Member.Email'
                                                    ]
                                                  )}
                                                </label>
                                              </label>
                                            </div>
                                          </div>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='far fa-id-card'></i>
                                            </span>
                                            <input
                                              type='text'
                                              name='email'
                                              className='form-control'
                                              placeholder={t('mail')}
                                              value={
                                                this.state.updateinfo.email
                                              }
                                              onChange={this.validateEmail}
                                            ></input>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row mb-3'>
                                        <div className='col'>
                                          <label>
                                            {t('Gender')} :
                                            <label className='text-danger'>
                                              {' '}
                                              *
                                            </label>
                                          </label>
                                          <select
                                            className='form-control'
                                            value={this.state.updateinfo.gender}
                                            name='gender'
                                            onChange={this.listchoose}
                                          >
                                            <option defaultValue={'...'}>
                                              ...
                                            </option>
                                            <option value={'MALE'}>
                                              {t('male')}
                                            </option>
                                            <option value={'FEMALE'}>
                                              {t('female')}
                                            </option>
                                            <option value={'OTHER'}>
                                              {t('not-specified')}
                                            </option>
                                          </select>
                                        </div>
                                        <div className='col'>
                                          <label>
                                            {t('ages')} :
                                            <label className='text-danger'>
                                              {' '}
                                              *
                                            </label>
                                          </label>
                                          <select
                                            className='form-control'
                                            value={this.state.updateinfo.age}
                                            name='age'
                                            onChange={this.listchoose}
                                          >
                                            <option defaultValue={'...'}>
                                              ...
                                            </option>
                                            <option value={'17'}>
                                              {t('below18')}
                                            </option>
                                            <option value={'18-25'}>
                                              {t('18-25')}
                                            </option>
                                            <option value={'26-45'}>
                                              {t('26-45')}
                                            </option>
                                            <option value={'46'}>
                                              {t('older45')}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className='row mb-3'>
                                        <div className='col nolpad'>
                                          <label htmlFor='register-form-cardno'>
                                            {t('province')} :{' '}
                                            <label className='text-danger'>
                                              {' '}
                                              *
                                            </label>
                                          </label>
                                          <select
                                            id='inputState'
                                            className='form-control'
                                            value={this.state.province}
                                            name='province'
                                            onChange={this.onInputaddress}
                                          >
                                            <option defaultValue={'...'}>
                                              ...
                                            </option>
                                            <option value={'Vientiane capital'}>
                                              {t('Vientiane capital')}
                                            </option>
                                            <option value={'Phongsali'}>
                                              {t('Phongsali')}
                                            </option>
                                            <option value={'Louang Namtha'}>
                                              {t('Louang Namtha')}
                                            </option>
                                            <option value={'Oudomxai'}>
                                              {t('Oudomxai')}
                                            </option>
                                            <option value={'Bokeo'}>
                                              {t('Bokeo')}
                                            </option>
                                            <option value={'Louang Phabang'}>
                                              {t('Louang Phabang')}
                                            </option>
                                            <option value={'Houaphan'}>
                                              {t('Houaphan')}
                                            </option>
                                            <option value={'Xaignabouli'}>
                                              {t('Xaignabouli')}
                                            </option>
                                            <option value={'Xiangkhoang'}>
                                              {t('Xiangkhoang')}
                                            </option>
                                            <option value={'Vientiane'}>
                                              {t('Vientiane')}
                                            </option>
                                            <option value={'Boli khamxai'}>
                                              {t('Boli khamxai')}
                                            </option>
                                            <option value={'Khammouan'}>
                                              {t('Khammouan')}
                                            </option>
                                            <option value={'Savannakhet'}>
                                              {t('Savannakhet')}
                                            </option>
                                            <option value={'Salavan'}>
                                              {t('Salavan')}
                                            </option>
                                            <option value={'Xekong'}>
                                              {t('Xekong')}
                                            </option>
                                            <option value={'Champasak'}>
                                              {t('Champasak')}
                                            </option>
                                            <option value={'Attapu'}>
                                              {t('Attapu')}
                                            </option>
                                            <option value={'Sisomboun'}>
                                              {t('Sisomboun')}
                                            </option>
                                            <option value={'foreign'}>
                                              {t('foreign')}
                                            </option>
                                          </select>
                                        </div>
                                        <div className='col norpad'>
                                          <label htmlFor='register-form-cardno'>
                                            {t('district')} :{' '}
                                            <label className='text-danger'>
                                              {' '}
                                              *
                                            </label>
                                          </label>
                                          <select
                                            className='form-control'
                                            value={this.state.district}
                                            name='district'
                                            onChange={this.onInputaddress}
                                          >
                                            <option defaultValue={'...'}>
                                              ...
                                            </option>
                                            {this.state.district_list}
                                          </select>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className='modal-footer'>
                                    <label className={this.state.status.color}>
                                      {t(this.state.status.update)}
                                    </label>
                                    <button
                                      type='button'
                                      className='btn btn-secondary'
                                      data-bs-dismiss='modal'
                                      onClick={this.close_btn}
                                    >
                                      {t('close')}
                                    </button>
                                    <button
                                      className='button button-3d button-primary nomargin'
                                      onClick={this.UpdateInfo}
                                    >
                                      {t('update')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='d-grid gap-2'>
                            <div
                              className='modal fade'
                              id='changepsswd'
                              data-bs-backdrop='static'
                              data-bs-keyboard='false'
                              tabIndex='-1'
                              aria-labelledby='staticBackdropLabel'
                              aria-hidden='true'
                            >
                              <div className='modal-dialog'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h4
                                      className='modal-title'
                                      id='changepsswd'
                                    >
                                      {t('editinff')}
                                    </h4>
                                    <button
                                      type='button'
                                      className='btn-close'
                                      data-bs-dismiss='modal'
                                      aria-label='Close'
                                      onClick={this.close_btn}
                                    ></button>
                                  </div>
                                  <div className='modal-body'>
                                    <form
                                      className='nobottommargin'
                                      onSubmit={this.onSubmitForm}
                                    >
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <label>{t('oldpass')} :</label>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='fas fa-key'></i>
                                            </span>
                                            <input
                                              type='password'
                                              name='old_password'
                                              minLength='8'
                                              className='form-control'
                                              placeholder='**********'
                                              value={
                                                this.state.changepass
                                                  .old_password
                                              }
                                              onChange={this.validatePass}
                                              required
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <div className='row'>
                                            <div className='col'>
                                              <label htmlFor='register-form-user'>
                                                {t('new_password')} :
                                                <label className='text-danger'>
                                                  {t(this.state.pass_warn)}
                                                </label>
                                              </label>
                                            </div>
                                          </div>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='fas fa-key'></i>
                                            </span>
                                            <input
                                              type='password'
                                              name='password'
                                              minLength='8'
                                              className='form-control'
                                              placeholder={t('password')}
                                              value={
                                                this.state.changepass.password
                                              }
                                              onChange={this.validatePass}
                                            ></input>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col_full'>
                                        <div className='mb-3'>
                                          <div className='row'>
                                            <div className='col'>
                                              <label htmlFor='register-form-user'>
                                                {t('ncfpass')} :
                                                <label className='text-danger'>
                                                  {t(this.state.new_pass_warn)}
                                                </label>
                                              </label>
                                            </div>
                                          </div>
                                          <div className='input-group'>
                                            <span className='input-group-text'>
                                              <i className='fas fa-key'></i>
                                            </span>
                                            <input
                                              type='password'
                                              name='password'
                                              minLength='8'
                                              className='form-control'
                                              placeholder={t('password')}
                                              value={this.state.cfpassword}
                                              onChange={this.validateNewPass}
                                            ></input>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className='modal-footer'>
                                    <label className={this.state.status.color}>
                                      {t(this.state.status.update)}
                                    </label>
                                    <button
                                      type='button'
                                      className='btn btn-secondary'
                                      data-bs-dismiss='modal'
                                      onClick={this.close_btn}
                                    >
                                      {t('close')}
                                    </button>
                                    <button
                                      className='button button-3d button-primary nomargin'
                                      onClick={this.Changepassword}
                                    >
                                      {t('update')}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-8 nobottommargin p-4'>
                    <div className='row'>
                      <Transaction />
                    </div>
                    <div className='row'>
                      <Transactionredeem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Tail />
          </>
        );
      } else {
        return <Loaderpage />;
      }
    } else {
      window.location.href = '/';
    }
  }
}
export default withTranslation()(User);
