import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Tail() {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className='container-fluid clearfix tailpart sticky-bottom'
        id='copyrights'
      >
        <div className='row'>
          <div className='col-sm'>
            <strong>
              <ul>
                <li>
                  {t('mknk')}
                  <ul>
                    <li>{t('mknktel')}</li>
                    <li>{t('opentime')}</li>
                    <li>
                      <a
                        style={{ textDecoration: 'none', color: '#4285F4' }}
                        href='https://goo.gl/maps/9Um6yMuqJGFzuZvw7'
                      >
                        <i className='fas fa-map-marker-alt'></i>&nbsp;
                        {t('mknkaddr')}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </strong>
          </div>
          <div className='col-sm'>
            <ul>
              <li>
                <p className='nobottommargin font-weight-bold'>{t('mkwn')}</p>
                <ul>
                  <li>{t('mkwntel')}</li>
                  <li>{t('opentime')}</li>
                  <li>
                    <a
                      style={{ textDecoration: 'none', color: '#4285F4' }}
                      href='https://goo.gl/maps/SyQDFRcRQGR4xoGT8'
                    >
                      <i className='fas fa-map-marker-alt'></i> &nbsp;
                      {t('mkwnaddr')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div style={{ fontSize: '0.7rem' }}>
              Copyrights © 2021 All Rights Reserved by MK Restaurant.
            </div>
          </div>
          <div className='col-sm'>
            <ul>
              <li>
                <p className='nobottommargin font-weight-bold'>
                  {t('mkitecc')}
                </p>
                <ul>
                  <li>{t('mkitecctel')}</li>
                  <li>{t('opentime')}</li>
                  <li>
                    <a
                      style={{ textDecoration: 'none', color: '#4285F4' }}
                      href='https://goo.gl/maps/SyQDFRcRQGR4xoGT8'
                    >
                      <i className='fas fa-map-marker-alt'></i> &nbsp;
                      {t('mkiteccaddr')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div style={{ fontSize: '0.7rem' }}>
              Copyrights © 2021 All Rights Reserved by MK Restaurant.
            </div>
          </div>
          <div className='col-sm'>
            <div className='link'>
              <a
                id='facebooklink'
                href='https://www.facebook.com/mkrestaurantlaos/'
              >
                <i className='fab fa-facebook-square'></i>
                &nbsp;
                <label style={{ textDecoration: 'none', color: '#4285F4' }}>
                  MK Restaurant Laos
                </label>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
