import Loader from "react-loader-spinner";
import React, { Component } from 'react';
export default class Loaderpage extends Component {
  //other logic
  render() {
    const style = { textAlign: 'center' };
    return (
      <div style={style}>
        <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
      </div>
    );
  }
}