import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import axios from './axios';

class Transaction extends Component {

    componentDidMount() {
        axios.get(process.env.REACT_APP_API.concat('public/products/items')).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                transaction: res.data,
                pagination: {
                    ...this.state.pagination,
                    current_page: 1,
                    pages: (Math.floor((res.data.total - 1) / 10)) + 1
                }
            }
            ))
        }).catch(err => {
            console.log(err)
        })
    }
    constructor() {
        super();
        this.state = {
            token: {
                access_token: "",
                refresh_token: ""
            },
            transaction: {
                total: 0
            },
            pagination: {
                tag_pages: "",
                table: <tr><td></td><td></td><td></td><td></td></tr>,
                current_page: "",
                pages: ""
            }
        };
        this.select_page = this.select_page.bind(this);
        this.Nextpage = this.Nextpage.bind(this);
        this.Backpage = this.Backpage.bind(this);
        this.formatMoney = this.formatMoney.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }
    formatDate(dateString) {
        var thisDate = dateString.split('-');
        var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
        return newDate
    }
    select_page(event) {
        var to = ((event.target.value * 10) - 9);
        axios.get(process.env.REACT_APP_API.concat('public/products/items?limit=10&start=').concat(to)).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                transaction: res.data,
                pagination: {
                    ...this.state.pagination,
                    current_page: parseInt(event.target.value),
                }
            }
            ))
        })
    }
    formatMoney(amount) {
        return Intl.NumberFormat('la-LA').format(amount);
    }
    Nextpage(event) {
        var to = (this.state.pagination.current_page + 1);
        axios.get(process.env.REACT_APP_API.concat('public/products/items?limit=10&start=').concat(String(to * 10 - 9))).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                transaction: res.data,
                pagination: {
                    ...this.state.pagination,
                    current_page: to,
                }
            }
            ))
        })

    }
    Backpage(event) {
        var to = (this.state.pagination.current_page - 1);
        axios.get(process.env.REACT_APP_API.concat('public/products/items?limit=10&start=').concat(String(to * 10 - 9))).then(res => {
            this.setState(Object.assign(
                this.state, {
                ...this.state,
                transaction: res.data,
                pagination: {
                    ...this.state.pagination,
                    current_page: to,
                }
            }
            ))
        })
    }
    render() {
        const { t } = this.props;
        var table = [];
        var pages = [];
        var endtable = [];
        var date;
        var keys;
        var time;
        if (this.state.transaction.total) {
            console.log(this.state)
            for (keys in this.state.transaction.product_redeems) {
                date = this.state.transaction.product_redeems[keys].created_at.substring(0, 10);
                time = this.state.transaction.product_redeems[keys].created_at.substring(11, 19);
                table.push(
                    <tr className="active-row" key={keys}>
                        <td>{this.formatDate(date)}</td>
                        <td>{time}</td>
                        <td>{this.state.transaction.product_redeems[keys].product.name}</td>
                        <td>{this.state.transaction.product_redeems[keys].redeem_code}</td>
                        <td>{this.state.transaction.product_redeems[keys].point}</td>
                        <td>{t(this.state.transaction.product_redeems[keys].status)}</td>
                    </tr>)
            }
            if (1 === (this.state.pagination.current_page)) {
                pages.push(<li className="page-item disabled" key="back"><button className="page-link" >&lt;</button></li>)
            } else {
                pages.push(<li className="page-item " key="back"><button className="page-link" onClick={this.Backpage}>&lt;</button></li>)
            }
            for (keys = 1; keys <= (Math.floor((this.state.transaction.total - 1) / 10) + 1); keys++) {
                if (keys === (this.state.pagination.current_page)) {
                    pages.push(<li className="page-item" aria-current="page" key={keys}><span className="page-link">{keys}</span></li>)
                } else {
                    pages.push(<li className="page-item" key={keys}><button className="page-link" value={keys} onClick={this.select_page}>{keys}</button></li>)
                }
            }
            if ((Math.floor((this.state.transaction.total - 1) / 10) + 1) <= this.state.pagination.current_page) {
                pages.push(<li className="page-item disabled" key="next"><button className="page-link" >&gt;</button></li>)
            } else {
                pages.push(<li className="page-item" key="next"><button className="page-link" onClick={this.Nextpage}>&gt;</button></li>)
            }
        } else {
            endtable.push(
                <div className="divcenter">
                    <figure className="text-center">
                        <blockquote className="blockquote">
                            <p>{t('notrans')}</p>
                        </blockquote>
                    </figure>
                </div>
            )
        }
        return (
            <>
                <div className="row fix-table">
                    <div className="card card-info">
                        <h2 className="card-title">{t('redeemhis')}</h2>
                        <div className="table-responsive">
                            <table className="table styled-table">
                                <thead>
                                    <tr>
                                        <th>{t('dates')}</th>
                                        <th>{t('time')}</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('redeem_code')}</th>
                                        <th>{t('point')}</th>
                                        <th>{t('status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {table}
                                </tbody>
                            </table>
                            {endtable}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            {pages}
                        </ul>
                    </nav>
                </div>
            </>
        )
    }

}
export default withTranslation()(Transaction);