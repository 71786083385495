import React from 'react'
import { useTranslation } from 'react-i18next';

function Navigate() {

	const { t, i18n } = useTranslation();
	const changeLanguage = lng => {
		i18n.changeLanguage(lng);
		localStorage.setItem("mk_lang", lng);
	};

	return (
		<header>
			<nav className="navbar navbar-expand-sm navbar-light sticky-top " style={{ backgroundColor: "#F4F4F4" }}>
				<div className="container-fluid container px-4">
					<div>
						<a href="/" className="navbar-brand">
							<img src="media/logo_mk03.png" alt="MK"></img>
						</a>
					</div>
					<button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarToggle">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse " id="navbarToggle">
						<ul className="navbar-nav ms-auto">
							<li className="nav-item"><a href="/register" className="nav-link">
								<i className="fas fa-file-alt"></i>
								&nbsp;{t('register')}
							</a>
							</li>
							<li className="nav-item">
								<a href="/" className="nav-link ">
									<i className="fas fa-sign-in-alt"></i>
									&nbsp;{t('login')}
								</a></li>
							<li className="nav-item respon-pc">
								<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('la')}>
									<i className="flag-icon flag-icon-la"></i>
								</button>
							</li>
							<li className="nav-item respon-pc" >
								<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('en')}>
									<i className="flag-icon flag-icon-us"></i>
								</button>
							</li>
							<li className="nav-item respon-mb">
								<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('la')}>
									<i className="flag-icon flag-icon-la"></i>
									&nbsp;
									<label>ພາສາລາວ</label>
								</button>
							</li>
							<li className="nav-item respon-mb" >
								<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('en')}>
									<i className="flag-icon flag-icon-us"></i>
									&nbsp;
									<label>English</label>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>)
}
export default Navigate;