import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Navigate_Logged() {

	const { t, i18n } = useTranslation();
	const changeLanguage = lng => {
		i18n.changeLanguage(lng);
		localStorage.setItem("mk_lang", lng);
	};
	function logout() {
		localStorage.clear();
	}
	return (
		<nav className="navbar navbar-expand-sm navbar-light bg-light sticky-top ">
			<div className="container-fluid container ">
				<div>
					<a href="/user" className="navbar-brand">
						<img src="/media/logo_mk03.png" alt="MK"></img>
					</a>
				</div>
				<button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarToggle">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse " id="navbarToggle">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item"><a href="/" className="nav-link" onClick={logout}>
							<i className="fas fa-sign-out-alt"></i>
							<label>
								{t('logout')}
							</label>
						</a></li>
						<li className="nav-item respon-pc">
							<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('la')}>
								<i className="flag-icon flag-icon-la"></i>
							</button>
						</li>
						<li className="nav-item respon-pc" >
							<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('en')}>
								<i className="flag-icon flag-icon-us"></i>
							</button>
						</li>
						<li className="nav-item respon-mb">
							<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('la')}>
								<i className="flag-icon flag-icon-la"></i>
								&nbsp;
								<label>ພາສາລາວ</label>
							</button>
						</li>
						<li className="nav-item respon-mb" >
							<button className="nav-link" style={{ border: "none", background: "none" }} onClick={() => changeLanguage('en')}>
								<i className="flag-icon flag-icon-us"></i>
								&nbsp;
								<label>English</label>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</nav>)
}
