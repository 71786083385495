import axios from 'axios';
import Crypto from 'crypto-js';

axios.interceptors.request.use(
  (request) => {
    var config = request;
    var acc_token;
    if (
      request.url !== process.env.REACT_APP_API.concat('auth/member-login') &&
      request.url !== process.env.REACT_APP_API.concat('auth/member-refresh') &&
      request.url !== process.env.REACT_APP_API.concat('members')
    ) {
      const a = localStorage.getItem('access_token');
      if (a) {
        acc_token = Crypto.AES.decrypt(a, 'MKSUKI').toString(Crypto.enc.Utf8);
      }
      config.headers['cache-control'] = 'no-store';
      config.headers['Pragma'] = 'no-cache';
      config.headers['Content-Type'] = 'application/json';
      config.headers['Authorization'] = 'Bearer '.concat(acc_token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const req = error.config;
    if (error.response.status !== 404 || error.response.status) {
      if (
        error.response.config.url !==
          process.env.REACT_APP_API.concat('auth/member-refresh') &&
        error.response.config.url !==
          process.env.REACT_APP_API.concat('auth/member-login') &&
        error.response.config.url !==
          'https://api.mkrestaurant.la/api/v1/members' &&
        error.response.status === 401
      ) {
        var ref_token;
        const b = localStorage.getItem('refresh_token');
        if (b) {
          ref_token = Crypto.AES.decrypt(b, 'MKSUKI').toString(Crypto.enc.Utf8);
        }
        return axios
          .post(process.env.REACT_APP_API.concat('auth/member-refresh'), {
            refresh_token: ref_token,
          })
          .then((res) => {
            localStorage.setItem(
              'access_token',
              Crypto.AES.encrypt(res.data.access_token, 'MKSUKI').toString()
            );
            localStorage.setItem(
              'refresh_token',
              Crypto.AES.encrypt(res.data.refresh_token, 'MKSUKI').toString()
            );
            axios.defaults.headers.common['Authorization'] = 'Bearer '.concat(
              res.data.access_token
            );
            return axios(req);
          })
          .catch((err) => {
            return Promise.reject(error);
          });
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
export default axios;
