import React,{Component} from 'react'
import './css/App.css';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom'
import Register from './Register'
import Login from './Login'
import User from './user'
import Redeem from './redeem'
import './i18n';
class App extends Component {
  render(){
    return (<Router>
              <Switch>
                <Route exact path="/user">
                  <User />               
                </Route>
                <Route exact path="/">
                  <Login />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
                <Route exact path="/redeem">
                  <Redeem />               
                </Route>
              </Switch>
            </Router>
    );
  }
}

export default App;
