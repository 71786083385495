const lao_address = {
  'Vientiane capital': [
    'Chanthabuly',
    'Sikhottabong',
    'Xaysetha',
    'Sisattanak',
    'Naxaithong',
    'Xaythany',
    'Hadxaifong',
    'Sangthong',
    'Parkngum'
  ],
  'Phongsali': [
    'Phongsaly',
    'May',
    'Khua',
    'Samphanh',
    'Bounneua',
    'Nhot ou',
    'Boontai'
  ],
  'Louang Namtha': [
    'Luangnamtha',
    'Sing',
    'Long',
    'Viengphoukha',
    'Nalae'
  ],
  'Oudomxai': [
    'Xay',
    'La',
    'Namor',
    'Nga',
    'Beng',
    'Hoon',
    'Pakbeng'
  ],
  'Bokeo': [
    'Houixay',
    'Tongpheung',
    'Meung',
    'Phaoudom',
    'Paktha'
  ],
  'Louang Phabang': [
    'Luangprabang',
    'Xiengngeun',
    'Nan',
    'Parkou',
    'Nambak',
    'Ngoi',
    'Pakxeng',
    'Phonxay',
    'Chomphet',
    'Viengkham',
    'Phoukhoune',
    'Phonthong'
  ],
  'Houaphan': [
    'Xamneua',
    'Xiengkhor',
    'Hiam',
    'Viengxay',
    'Huameuang',
    'Xamtay',
    'Sopbao',
    'Add',
    'Kuan',
    'Xone'
  ],
  'Xaignabouli': [
    'Xayabury',
    'Khop',
    'Hongsa',
    'Ngeun',
    'Xienghone',
    'Phieng',
    'Parklai',
    'Kenethao',
    'Botene',
    'Thongmyxay',
    'Xaysathan'
  ],
  'Xiangkhoang': [
    'Pek',
    'Kham',
    'Nonghed',
    'Khoune',
    'Mork',
    'Phookood',
    'Phaxay'
  ],
  'Vientiane': [
    'Phonhong',
    'Thoulakhom',
    'Keooudom',
    'Kasy',
    'Vangvieng',
    'Feuang',
    'Xanakham',
    'Mad',
    'Viengkham',
    'Hinherb',
    'Meun'
  ],
  'Boli khamxai': [
    'Pakxane',
    'Thaphabath',
    'Pakkading',
    'Bolikhanh',
    'Khamkheuth',
    'Viengthong',
    'Xaychamphone'
  ],
  'Khammouan': [
    'Thakhek',
    'Mahaxay',
    'Nongbok',
    'Hinboon',
    'Nhommalath',
    'Bualapha',
    'Nakai',
    'Xebangfay',
    'Xaybuathong',
    'Khounkham'
  ],
  'Savannakhet': [
    'Kaisone Phomvihane',
    'Outhoumphone',
    'Atsaphangthong',
    'Phine',
    'Xepon',
    'Nong',
    'Thapangthong',
    'Songkhone',
    'Champhone',
    'Xonbuly',
    'Xaybouly',
    'Vilabuly',
    'Atsaphone',
    'Xayphoothong',
    'Phalanxay'
  ],
  'Salavan': [
    'Saravane',
    'Ta oi',
    'Toomlam',
    'Lakhonepheng',
    'Vapy',
    'Kongxedone',
    'Lao ngarm',
    'Samoi'
  ],
  'Xekong': [
    'Lamarm',
    'Kaleum',
    'Dakcheung',
    'Thateng'
  ],
  'Champasak': [
    'Pakse',
    'Sanasomboon',
    'Bachiangchaleunsook',
    'Pakxong',
    'Pathoumphone',
    'Phonthong',
    'Champasak',
    'Sukhuma',
    'Moonlapamok',
    'Khong'
  ],
  'Attapu': [
    'Xaysettha',
    'Samakkixay',
    'Sanamxay',
    'Sanxay',
    'Phouvong'
  ],
  'Sisomboun': [
    'Anouvong',
    'Thathom',
    'Longcheng',
    'Hom',
    'Longsan'
  ],
  'foreign': [
    'foreign'
  ]
}

export default lao_address;